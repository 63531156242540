import React, { useState, useEffect } from "react";
import "./carousal.css";

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const images = [
    require("../assets/img1.jpg"),
    require("../assets/img2.jpg"),
    require("../assets/img3.jpg"),
    // Add more image URLs here
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((currentIndex + 1) % images.length);
    }, 3000); // Change the interval duration (in milliseconds) as needed

    return () => clearInterval(interval);
  }, [currentIndex, images.length]);

  return (
    <div className="carousel">
      <div className="carousel-inner">
        <img
          src={images[currentIndex]}
          alt="Carousel"
          className="carousel-image rounded-lg"
        />
      </div>
    </div>
  );
};

export default Carousel;
