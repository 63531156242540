import axiosInstance from "../axios";
import AuthContext from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useState, useContext } from "react";
import Carousel from "./carousal";
const LoginForm = ({ onSubmit }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    onSubmit({ email, password });
  };

  return (
    <form onSubmit={handleSubmit} className="p-6">
      <div className="mb-4">
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>
      <div className="mb-4">
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>
      <button
        type="submit"
        className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
      >
        Login
      </button>
    </form>
  );
};

const SignupForm = ({ onSubmit }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      alert("Passwords don't match");
      return;
    }
    onSubmit({ email, password });
  };

  return (
    <form onSubmit={handleSubmit} className="p-6">
      <div className="mb-4">
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>
      <div className="mb-4">
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>
      <div className="mb-4">
        <input
          type="password"
          placeholder="Confirm Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>
      <button
        type="submit"
        className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
      >
        Sign Up
      </button>
    </form>
  );
};

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("login");

  const handleSubmitLogin = async (e) => {
    console.log("Clicked signin");
    console.log(e);
    const { email, password } = e;

    try {
      const response = await axiosInstance.post("/api/user/login", {
        email,
        password,
      });
      console.log(email, password);
      console.log("Response:", response);
      if (response.status === 200) {
        const token = response.data.jwttoken;
        login(token);
        console.log("Token:", token);
        navigate("/");
      } else {
        alert("Email or password incorrect");
      }
    } catch (error) {
      console.error("Error signing in:", error);
      alert("Error signing in");
    }
  };
  const handleSubmitSignup = async (e) => {
    console.log(e);
    const { email, password, confirmPassword } = e;

    try {
      const response = await axiosInstance.post("/api/user/register", {
        email,
        password,
      });
      console.log("Response:", response);
      console.log("status", response.status);
      if (response.status === 200) {
        const token = response.data.jwttoken;
        login(token);
        console.log("Token:", token);
        navigate("/");
      } else {
        alert("Error signing up");
        console.log("Error signing up:", response);
        alert("Error signing up");
      }
    } catch (error) {
      console.error("Error signing up:", error);
      alert("Error signing up");
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col justify-center items-center p-4">
      <header className="mb-8 text-center">
        <svg
          className="inline-block text-blue-600 mb-2 w-12 h-12"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M15 13a3 3 0 11-6 0 3 3 0 016 0z"
          />
        </svg>
        <h1
          className="text-3xl font-bold text-gray-800"
          style={{ fontFamily: "Arial, sans-serif" }}
        >
          Photoai
        </h1>
        <p className="text-gray-600">AI-generated photo-realistic images</p>
        <div>
          <p className="text-center mb-4">
            🔥 Create your images now and show your imagination to the world! 🔥
          </p>
          <Carousel />
        </div>
      </header>

      <div className="w-full max-w-md bg-white rounded-lg shadow-md overflow-hidden">
        <div className="flex border-b">
          <button
            className={`flex-1 py-2 px-4 text-center ${
              activeTab === "login"
                ? "bg-blue-500 text-white"
                : "bg-gray-200 text-gray-700"
            }`}
            onClick={() => setActiveTab("login")}
          >
            Login
          </button>
          <button
            className={`flex-1 py-2 px-4 text-center ${
              activeTab === "signup"
                ? "bg-blue-500 text-white"
                : "bg-gray-200 text-gray-700"
            }`}
            onClick={() => setActiveTab("signup")}
          >
            Sign Up
          </button>
        </div>
        {activeTab === "login" ? (
          <LoginForm onSubmit={handleSubmitLogin} />
        ) : (
          <SignupForm onSubmit={handleSubmitSignup} />
        )}
      </div>

      <div className="mt-8 flex justify-center space-x-4 text-sm text-gray-600">
        <a
          onClick={(e) => {
            e.preventDefault();
            navigate("/TermsandConditions");
          }}
          href="#"
          className="hover:underline"
        >
          Terms&conditions
        </a>
        <a
          onClick={(e) => {
            e.preventDefault();
            navigate("/RefundPolicy");
          }}
          href="#"
          className="hover:underline"
        >
          RefundPolicy
        </a>
        <a
          onClick={(e) => {
            e.preventDefault();
            navigate("/PrivacyPolicy");
          }}
          href="#"
          className="hover:underline"
        >
          PrivacyPolicy
        </a>
      </div>
    </div>
  );
};

export default SignIn;
