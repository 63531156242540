import React, { useState, useEffect, useContext } from "react";
import { useSpring, animated, config } from "react-spring";
import { load } from "@cashfreepayments/cashfree-js";
import AuthContext from "./context/AuthContext";
import axiosInstance from "./axios";
import Header from "./componants/header";
import InfoCard from "./componants/home/infocard";
import LogoutButton from "./componants/logout";
import JSZip from "jszip";
import Loading from "./componants/Loading";

const AnimatedText = ({ children }) => {
  const props = useSpring({
    to: { opacity: 1, transform: "translateY(0)" },
    from: { opacity: 0, transform: "translateY(50px)" },
    config: config.molasses,
  });

  return <animated.div style={props}>{children}</animated.div>;
};

const Section = ({ title, children }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="w-full max-w-7xl mx-auto my-12 px-4 cursor-pointer"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <h2 className="text-3xl font-bold mb-6 text-center">{title}</h2>
      {isHovered && children}
    </div>
  );
};

const Finetune = () => {
  const fadeIn = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    config: config.molasses,
  });

  const [sessionid, setsessionid] = useState(null);
  const { token } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [pulseProps, setPulse] = useSpring(() => ({
    transform: "scale(1)",
    config: config.wobbly,
  }));

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setPulse({ transform: "scale(1.05)" });
      setTimeout(() => setPulse({ transform: "scale(1)" }), 500);
    }, 2000);

    return () => clearInterval(intervalId);
  }, [setPulse]);

  let cashfree;
  const initializeSDK = async () => {
    try {
      cashfree = await load({
        mode: "production",
      });
      console.log(cashfree);
    } catch (error) {
      console.error("Error initializing SDK:", error);
    }
  };

  const handleClick = async () => {
    console.log("Clicked");
    try {
      const response = await axiosInstance.post(
        "/api/payment/ordercreate",
        {
          order_amount: 299,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        console.log("Response:", response.data);
        await initializeSDK(); // Wait for SDK initialization
        const doPayment = async () => {
          try {
            console.log("Sessionid:", response.data);
            let checkoutOptions = {
              paymentSessionId: response.data,
              redirectTarget: "_self",
            };
            console.log("CheckoutOptions:", checkoutOptions);
            await cashfree.checkout(checkoutOptions);
          } catch (error) {
            console.error("Error during payment:", error);
          }
        };
        await doPayment(); // Proceed with payment
      } else {
        console.log("Error in response)");
        alert("something went wrong try again");
      }
    } catch (error) {
      console.error("Error creating order:", error);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center h-full space-y-4">
      <p>
        <strong>Note:</strong>
        for the best results. First, you need to train the AI model with an
        image of your face. The model will learn from that image and generate
        photo-realistic images for you.
      </p>
      <p>
        This training process may take around 30 minutes to complete. Once the
        training is done, you can enjoy your photo-realistic images. Please
        ensure you have a clear image of your face
      </p>
      <strong>The training process costs 299 rupees</strong>

      <button
        onClick={handleClick}
        className="bg-blue-500 text-white font-bold py-2 px-4 rounded"
      >
        Fine-tune
      </button>
    </div>
  );
};

const PhotoUpload = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [inputText, setInputText] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [processStatus, setProcessStatus] = useState(null);
  const { token } = useContext(AuthContext);
  useEffect(() => {
    const checkProcessStatus = async () => {
      if (!processStatus) {
        setLoading(true);
      }

      try {
        const response = await axiosInstance.get(
          "/api/finetune/checkprocessstatus",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.status === 200) {
          setProcessStatus(response.data.status);
          console.log("Process status:", response.data.status);
          console.log("Response:", response.data);
          setLoading(false);
        } else {
          console.log("Error in response");
          alert("Something went wrong. Please try again.");
          setLoading(false);
        }
      } catch (error) {
        console.error("Error checking process status:", error);

        setLoading(false);
      }
    };

    const startInterval = () => {
      const intervalId = setInterval(checkProcessStatus, 500000); // Check every 5 seconds
      return () => clearInterval(intervalId);
    };

    // Send the initial request
    checkProcessStatus().then(() => {
      // Start the interval after the initial request is completed
      const cleanup = startInterval();
      return cleanup;
    });
  }, [token]);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    if (files.length >= 12 && files.length <= 16) {
      setSelectedFiles(files);
    } else {
      alert("Please select between 12 and 16 photos.");
      setSelectedFiles([]);
    }
  };

  const handleTextSubmit = async () => {
    try {
      console.log("Clicked");
      setLoading(true);
      const response = await axiosInstance.post(
        "/api/finetune/generate-image",
        { text: inputText },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Response:", response);
      if (response.status === 200) {
        setImageUrl(response.data.image_url);
        console.log("Image URL:", response.data);
        setLoading(false);
      } else {
        console.log("Error in response");
        alert("Something went wrong. Please try again.");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error generating image:", error);
      alert("Something went wrong. Please try again.");
      setLoading(false);
    }
  };

  const handleUpload = async () => {
    setLoading(true);
    if (selectedFiles.length === 0) {
      alert("Please select photos first.");
      setLoading(false);
      return;
    }

    const zip = new JSZip();
    selectedFiles.forEach((file, index) => {
      zip.file(`photo_${index + 1}.jpg`, file);
    });

    try {
      const zipBlob = await zip.generateAsync({ type: "blob" });
      // TODO: Upload the zip file to the server and start the training process
      console.log("Zip file generated:", zipBlob);

      // Create FormData and append the zip file and steps
      const formData = new FormData();
      formData.append("trainingImagesZip", zipBlob);
      formData.append("steps", "1000");

      // Send the request to the server
      const response = await axiosInstance.post(
        "/api/finetune/create",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        // Set the process status to "running"
        setProcessStatus("starting");
      } else {
        console.error("Error in response:", response);
      }

      setLoading(false);
    } catch (error) {
      console.error("Error generating zip file:", error);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center">
      {loading ? (
        <Loading />
      ) : (
        <>
          {processStatus === null ? (
            <>
              <p className="mb-4">
                <strong>Note:</strong> Please upload between 12 and 16 photos of
                yourself. The AI model will generate photo-realistic images
                based on these photos.
                <strong>
                  only your face should be there so that ai will not confuse
                </strong>
                Upload all types of your images with side and front face and
                fullbody images.all should be clear and high quality images for
                better results.
              </p>
              <input
                type="file"
                accept="image/*"
                multiple
                onChange={handleFileChange}
                className="mb-4"
              />
              <button
                onClick={handleUpload}
                className="bg-blue-500 text-white font-bold py-2 px-4 rounded"
              >
                Upload Photos
              </button>
            </>
          ) : processStatus === "starting" ? (
            <p>
              Training is running. It will take around 30 minutes to complete.
              sit back and relax
            </p>
          ) : processStatus === "completed" || "completed\n" ? (
            <div className="flex flex-col items-center justify-center space-y-3">
              <p>Generate the image using name of tak.</p>
              <p>
                <strong>prompt ex: tak taking to audience</strong>
              </p>
              <p>Enter the prompt to generate the image</p>
              <input
                type="text"
                value={inputText}
                onChange={(e) => setInputText(e.target.value)}
                placeholder="Enter text"
                className="mb-4 p-2 border border-gray-300 rounded"
              />
              <button
                onClick={handleTextSubmit}
                className="bg-blue-500 text-white font-bold py-2 px-4 rounded mb-4"
              >
                Generate Image
              </button>

              {imageUrl && (
                <>
                  <p>click the image to download</p>
                  <a href={imageUrl} target="_blank" rel="noopener noreferrer">
                    <img
                      src={imageUrl}
                      alt="Generated"
                      className="max-w-screen"
                    />
                  </a>
                </>
              )}
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};

const Home = () => {
  const [isFinetuned, setIsFinetuned] = useState(false);
  const { token } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const checkFinetuneStatus = async () => {
      setLoading(true);
      try {
        const response = await axiosInstance.get(
          "/api/user/finetuneamountstatus",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.status === 200) {
          if (response.data.finetuneamountstatus === "true") {
            setIsFinetuned(true);
          }
          setLoading(false);
          console.log("Finetune status:", response.data.finetuneamountstatus);
        } else {
          console.log("Error in response)");
          alert("something went wrong try again");
          setLoading(false);
        }
      } catch (error) {
        console.error("Error checking finetune status:", error);
        setLoading(false);
      }
    };

    checkFinetuneStatus();
  }, [token]);

  return (
    <div className="flex flex-col h-screen">
      <Header />
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div className="flex-grow flex items-center justify-center">
          {isFinetuned ? (
            <PhotoUpload />
          ) : (
            <Finetune isFinetuned={isFinetuned} />
          )}
        </div>
      )}
    </div>
  );
};

export default Home;
