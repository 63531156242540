import React, { useState, useEffect } from "react";
import Lottie from "react-lottie";
import loadingAnimation from "../assets/Loading.json";

const Loading = () => {
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowMessage(true);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen">
      <Lottie options={defaultOptions} height={200} width={200} />
      {showMessage && (
        <p>Don't press back, it will take a minute to complete</p>
      )}
    </div>
  );
};

export default Loading;
