const PrivacyPolicy = () => (
  <div className="prose max-w-3xl mx-auto">
    <p>
      At ZeroScript Company, we are committed to protecting your privacy. This
      Privacy Policy explains how we collect, use, and safeguard your personal
      information.
    </p>
    <h3>1. Information Collection</h3>
    <p>
      We collect information you provide directly to us, such as when you create
      an account, use our services, or contact us for support.
    </p>
    <h3>2. Use of Information</h3>
    <p>
      We use the information we collect to provide, maintain, and improve our
      services, to develop new ones, and to protect our company and users.
    </p>
    <h3>3. Data Security</h3>
    <p>
      We implement appropriate technical and organizational measures to protect
      the security of your personal information.
    </p>
    <h3>4. Your Rights</h3>
    <p>
      You have the right to access, correct, or delete your personal
      information. Please contact us at privacy@photoai.com for any
      privacy-related requests.
    </p>
  </div>
);

export default PrivacyPolicy;
