import React, { useContext } from "react";
import { Route, Navigate } from "react-router-dom";
import AuthContext from "./context/AuthContext";

const PrivateRoute = ({ children, ...rest }) => {
  const { token } = useContext(AuthContext);
  console.log("Token in PrivateRoute:", token);

  return token ? children : <Navigate to="/signin" />;
};

export default PrivateRoute;
