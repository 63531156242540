import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import AuthContext from "../context/AuthContext"; // Ensure correct import
import Confetti from "react-confetti";
import { useWindowSize } from "react-use";
import axiosInstance from "../axios";

const Orders = () => {
  const [status, setStatus] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { width, height } = useWindowSize();
  const { token } = useContext(AuthContext); // Move useContext outside useEffect

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const returnId = queryParams.get("return_id");
    console.log("Return ID:", returnId);

    const confirmPayment = async () => {
      try {
        const response = await axiosInstance.post(
          "/api/payment/ordercheck",
          {
            return_id: returnId,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log("Response:", response);
        console.log("Status:", response.data.status);
        if (response.data.status === "Success") {
          setStatus("success");
          setTimeout(() => {
            navigate("/");
          }, 3000); // Navigate to home after 3 seconds
        } else {
          setStatus("error");
          setTimeout(() => {
            navigate("/");
          }, 3000);
        }
      } catch (error) {
        console.error("Error confirming payment:", error);
        setStatus("error");
      }
    };

    confirmPayment();
  }, [location.search, token]);

  return (
    <div>
      <h1 style={{ textAlign: "center" }}>Order Confirmation</h1>
      {status === "success" && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <FaCheckCircle color="green" size={50} />
          <Confetti width={width} height={height} />
          <p>Payment Successful!</p>
        </div>
      )}
      {status === "error" && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <FaTimesCircle color="red" size={50} />
          <p>Payment Failed!</p>
        </div>
      )}
    </div>
  );
};

export default Orders;
