import React from "react";
import { useSpring, animated, config } from "react-spring";
import Logout from "./logout"; // Adjust the path as necessary

const AnimatedText = ({ children }) => {
  const props = useSpring({
    to: { opacity: 1, transform: "translateY(0)" },
    from: { opacity: 0, transform: "translateY(50px)" },
    config: config.molasses,
  });

  return <animated.div style={props}>{children}</animated.div>;
};

const Header = () => (
  <header className="bg-gray-800 text-white p-4 w-full flex justify-between items-center rounded-lg">
    <div className="max-w-7xl mx-auto flex-grow">
      <AnimatedText>
        <h1
          style={{ fontFamily: "Arial, sans-serif" }}
          className="text-xl md:text-2xl font-bold"
        >
          PhotoAi
        </h1>
      </AnimatedText>
    </div>
    <Logout /> {/* Add Logout component to the right end */}
  </header>
);

export default Header;
