import React, { useContext } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./home";
import SignIn from "./componants/SignIn";
import SignUp from "./componants/SignUp";
import Orders from "./componants/Order";
import PrivateRoute from "./PrivateRoute";
import AuthContext, { AuthProvider } from "./context/AuthContext";
import { ToastContainer } from "react-toastify";
import Loading from "./componants/Loading";
import TermsAndConditions from "./componants/landpage/termsandconditions";
import RefundPolicy from "./componants/landpage/refundpolicy";
import PrivacyPolicy from "./componants/landpage/privacypolicy";

function App() {
  return (
    <AuthProvider>
      <Router>
        <AppContent />
      </Router>
    </AuthProvider>
  );
}

function AppContent() {
  const { loading } = useContext(AuthContext);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-7xl mx-auto px-4 py-12">
        <Routes>
          <Route
            path="/"
            element={
              <PrivateRoute>
                <Home />
              </PrivateRoute>
            }
          />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/Orders" element={<Orders />} />
          <Route path="/TermsandConditions" element={<TermsAndConditions />} />
          <Route path="/RefundPolicy" element={<RefundPolicy />} />
          <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
