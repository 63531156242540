const RefundPolicy = () => (
  <div className="prose max-w-3xl mx-auto">
    <p>
      At ZeroScript Company., we strive to ensure your satisfaction with our
      AI-generated photo services. Please review our refund and cancellation
      policy below:
    </p>
    <h3>Refunds</h3>
    <p>
      If you are unsatisfied with the quality of the AI-generated images, you
      may request a refund within 7 days of purchase. Refund requests will be
      evaluated on a case-by-case basis.
    </p>
    <h3>Cancellations</h3>
    <p>
      You may cancel your order before the AI-generation process begins. Once
      the process has started, cancellations are not possible due to the nature
      of our service.
    </p>
    <h3>Process</h3>
    <p>
      To request a refund or cancellation, please contact our support team at
      support@photoai.com with your order details and reason for the request.
    </p>
  </div>
);
export default RefundPolicy;
