const TermsAndConditions = () => (
  <div className="prose max-w-3xl mx-auto">
    <p>
      These Terms and Conditions govern your use of Zeroscript Company.'s
      services. By using our services, you agree to these terms.
    </p>
    <h3>1. Use of Service</h3>
    <p>
      You agree to use our AI-generated photo services for lawful purposes only.
      Any misuse or attempt to abuse our systems is strictly prohibited.
    </p>
    <h3>2. Intellectual Property</h3>
    <p>
      The AI-generated images provided by our service are licensed to you for
      personal or commercial use, subject to our licensing terms. You may not
      claim ownership of the AI model or the generation process.
    </p>
    <h3>3. Privacy</h3>
    <p>
      We respect your privacy. Please refer to our Privacy Policy for
      information on how we collect, use, and protect your personal data.
    </p>
  </div>
);

export default TermsAndConditions;
